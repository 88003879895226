import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NavBar: React.FC<{ signOut: () => void; user: any }> = ({
  signOut,
  user,
}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(); // Sign out the user
    navigate("/"); // Optionally navigate to the home page after logout
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/">Transcribe</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
        {user && (
          <Nav>
            <Nav.Link>
              <Button
                variant="outline-light"
                onClick={handleLogout}
                className="ms-auto"
                style={{ marginLeft: "auto" }}
              >
                Logout
              </Button>
            </Nav.Link>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
