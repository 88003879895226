import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import Layout from "./components/Layout";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "7oi01sded0302bhpkrqa9i222h",
      userPoolId: "eu-central-1_Wcy0wEjAI",
    },
  },
});

const App: React.FC = () => {
  return (
    <div>
      <Authenticator variation="modal" loginMechanisms={["email"]}>
        {({ signOut = () => {}, user }) => (
          <div>
            <Router>
              <Layout signOut={signOut} user={user}>
                <Routes>
                  <Route path="/" element={<Home user={user} />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                </Routes>
              </Layout>
            </Router>
          </div>
        )}
      </Authenticator>
    </div>
  );
};

export default App;
