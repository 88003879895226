import React from "react";
//import Layout from '../components/Layout';

const Contact: React.FC = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>This is the contact page content.</p>
    </div>
  );
};

export default Contact;
