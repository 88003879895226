import React from "react";
import FileUpload from "../components/FileUpload";
import FileList from "../components/FileList";
import "bootstrap/dist/css/bootstrap.min.css";

interface HomedProps {
  user: any;
}

const Home: React.FC<HomedProps> = (user) => {
  return (
    <div className="container text-center mt-5">
      <h1 className="display-4">Welcome to File-Transcribe</h1>
      <p className="lead mb-5">
        Upload your files securely and easily using the drag and drop feature
        below or click to choose files from your device.
      </p>
      <FileUpload
        apiGatewayUrl="https://r2j25ypbt7.execute-api.eu-central-1.amazonaws.com/dev"
        user={user}
      />
      <FileList
        apiGatewayUrl="https://r2j25ypbt7.execute-api.eu-central-1.amazonaws.com/dev"
        user={user}
      />
    </div>
  );
};

export default Home;
