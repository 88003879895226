import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import { generateSafeString } from "../utils/hashUtils";

interface FileUploadProps {
  apiGatewayUrl: string; // URL of the API Gateway
  user: any;
}

const FileUpload: React.FC<FileUploadProps> = ({ apiGatewayUrl, user }) => {
  const [uploadSuccess, setUploadSuccess] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const allowedExtensions = [".mp3", ".wav"];

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const fileExtension = file.name
        .slice(file.name.lastIndexOf("."))
        .toLowerCase();

      // This is just a pre-check, the backend does not allow for other filetypes anyway.
      if (!allowedExtensions.includes(fileExtension)) {
        setUploadSuccess(null);
        setErrorMessage(
          `Invalid file type. Only ${allowedExtensions.join(", ")} are allowed.`
        );
        return;
      }

      // Hash the username (email) and filename
      const hashedUsername = await generateSafeString(user.user.username);
      const hashedFileName = `${await generateSafeString(
        file.name
      )}${fileExtension}`;

      let token = null;
      try {
        token = (await fetchAuthSession()).tokens?.idToken;
      } catch (error) {
        console.error("Error fetching JWT:", error);
      }

      try {
        // Step 1: Request pre-signed URL from the API Gateway
        const response = await axios.post(
          `${apiGatewayUrl}/get-presigned-url-s3`,
          {
            action: "put",
            fileName: hashedFileName,
            filePrefix: hashedUsername,
            fileType: file.type,
          },
          {
            headers: {
              Authorization: `Bearer ${token?.toString()}`,
              "Content-Type": file.type,
            },
          }
        );
        const { presignedURL } = JSON.parse(response.data.body);

        // Step 2: Upload the file to S3 using the pre-signed URL
        const options = {
          headers: {
            "Content-Type": file.type,
          },
        };
        await axios.put(presignedURL, file, options);

        // Set the success message with the new file name
        setUploadSuccess(`${file.name} as ${hashedFileName}`);
        setErrorMessage(null); // Clear any previous errors
      } catch (error) {
        console.error("Error uploading file:", error);
        setErrorMessage("Error uploading file. Please try again.");
        setUploadSuccess(null); // Clear the success message
      }
    },
    [apiGatewayUrl]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #007bff",
          padding: "40px",
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: isDragActive ? "#e9ecef" : "#f8f9fa",
          width: "50%",
          margin: "0 auto",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.2s ease",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Drag & drop some files here, or click to select files</p>
        )}
      </div>

      {/* Success message */}
      {uploadSuccess && (
        <div
          className="alert alert-success mt-3"
          role="alert"
          style={{
            width: "50%",
            margin: "10px auto", // Centering the alert
            textAlign: "center", // Centering the text
          }}
        >
          File uploaded successfully! File name:{" "}
          <strong>{uploadSuccess}</strong>
        </div>
      )}

      {/* Error message */}
      {errorMessage && (
        <div
          className="alert alert-danger mt-3"
          role="alert"
          style={{
            width: "50%",
            margin: "10px auto", // Centering the alert
            textAlign: "center", // Centering the text
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
