import React from "react";
//import Layout from '../components/Layout';

const About: React.FC = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>This is the about page content.</p>
    </div>
  );
};

export default About;
