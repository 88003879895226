import { sha256 } from 'crypto-hash';

// Utility function to generate a SHA-256 hash
export const generateHash = async (input: string) => {
  return await sha256(input);
};

// Utility function to create a hash and limit the length
export const generateSafeString = async (input: string, maxLength: number = 32) => {
  const hash = await generateHash(input);
  return hash.slice(0, maxLength);
};
