import React, { useEffect, useState } from "react";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import { generateSafeString } from "../utils/hashUtils";

interface File {
  key: string;
  lastModified: string;
  size: number;
}

interface FileListProps {
  apiGatewayUrl: string; // URL of the API Gateway
  user: any;
}

const FileList: React.FC<FileListProps> = ({ apiGatewayUrl, user }) => {
  const [uploadedFiles, setUploadedFiles] = useState<
    Array<{ key: string; lastModified: string; size: number }>
  >([]);
  const [transcribedFiles, setTranscribedFiles] = useState<
    Array<{ key: string; lastModified: string; size: number }>
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        let token = null;
        try {
          token = (await fetchAuthSession()).tokens?.idToken;
        } catch (error) {
          console.error("Error fetching JWT:", error);
        }

        const response = await axios.post(
          `${apiGatewayUrl}/list-objects-s3`,
          {
            prefix: await generateSafeString(user.user.username),
          },
          {
            headers: {
              Authorization: `Bearer ${token?.toString()}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Follows from backend convention in S3
        const uploads: File[] = JSON.parse(response.data.body).filter(
          (file: File) => file.key.includes("/upload/")
        );
        const transcriptions: File[] = JSON.parse(response.data.body).filter(
          (file: File) => file.key.includes("/transcripts/")
        );

        // Remove username prefix from filename (everything before /...)
        const upload_filenames = uploads.map((file: any) => {
          file.key = file.key.substring(
            file.key.indexOf("/", file.key.indexOf("/") + 1) + 1
          );
          return file;
        });

        const transcribed_filenames = transcriptions.map((file: any) => {
          file.key = file.key.substring(
            file.key.indexOf("/", file.key.indexOf("/") + 1) + 1
          );
          return file;
        });

        setUploadedFiles(upload_filenames);
        setTranscribedFiles(transcribed_filenames);
      } catch (error) {
        console.error("Error fetching files:", error);
        setErrorMessage("Error fetching files. Please try again.");
      }
    };

    fetchFiles();
  }, [apiGatewayUrl]);

  const handleDownload = async (file: File) => {
    try {
      let token = null;
      try {
        token = (await fetchAuthSession()).tokens?.idToken;
      } catch (error) {
        console.error("Error fetching JWT:", error);
        return;
      }

      // Request pre-signed URL for download
      const response = await axios.post(
        `${apiGatewayUrl}/get-presigned-url-s3`,
        {
          action: "get",
          fileName: file.key.substring(file.key.lastIndexOf("/") + 1),
          filePrefix: await generateSafeString(user.user.username),
        },
        {
          headers: {
            Authorization: `Bearer ${token?.toString()}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { presignedURL } = JSON.parse(response.data.body);

      // Trigger file download
      window.open(presignedURL, "_blank");
    } catch (error) {
      console.error("Error generating download URL:", error);
      setErrorMessage("Error generating download URL. Please try again.");
    }
  };

  return (
    <div className="container mt-4">
      <div className="mb-5">
        <h2 className="mb-4">Your Uploads</h2>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <ul className="list-group">
          {uploadedFiles.length > 0 ? (
            uploadedFiles
              .filter((file) => !file.key.startsWith("."))
              .map((file) => (
                <li
                  key={file.key}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span>{file.key.replace(`${user.user.username}/`, "")}</span>
                  <span className="badge badge-primary badge-pill">
                    {(file.size / 1024).toFixed(2)} KB
                  </span>
                  <span>{new Date(file.lastModified).toLocaleString()}</span>
                </li>
              ))
          ) : (
            <li className="list-group-item text-center">No uploads found</li>
          )}
        </ul>
      </div>

      <div>
        <h2 className="mb-4">Your Transcriptions</h2>
        <ul className="list-group">
          {transcribedFiles.length > 0 ? (
            transcribedFiles
              .filter((file) => !file.key.startsWith("."))
              .map((file) => (
                <li
                  key={file.key}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  {/* Download Button */}
                  <button
                    className="btn btn-primary"
                    onClick={() => handleDownload(file)}
                  >
                    Download
                  </button>
                  <span>{file.key.replace(`${user.user.username}/`, "")}</span>
                  <span className="badge badge-primary badge-pill">
                    {(file.size / 1024).toFixed(2)} KB
                  </span>
                  <span>{new Date(file.lastModified).toLocaleString()}</span>
                </li>
              ))
          ) : (
            <li className="list-group-item text-center">
              No transcriptions found
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default FileList;
