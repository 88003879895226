import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

interface LayoutProps {
  children: React.ReactNode;
  signOut: () => void;
  user: any;
}

const Layout: React.FC<LayoutProps> = ({ children, signOut, user }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <NavBar signOut={signOut} user={user} />
      <main className="flex-grow-1">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
